<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Users List</span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start pt-2>
              <!-- <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Rows per page</span>
                <v-select
                  v-model="count"
                  color="#FF6907"
                  outlined
                  dense
                  hide-details
                  :items="[20, 30, 50]"
                ></v-select>
              </v-flex> -->
              <v-flex xs12 sm3 md3 lg3>
                <span class="subheading">Filter</span>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="Type To Search"
                  v-model="keyword"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 v-if="userlist && userlist.length > 0">
                <!-- <v-layout wrap justify-start>
                  <v-flex xs12 pt-4>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        lg4
                        sm6
                        md6
                        v-for="(item, index) in userlist"
                        :key="index"
                        pa-2
                      >
                        <v-card class="mb-4">
                          <v-card-title>
                            <span class="heading">{{ item.name }}</span>
                          </v-card-title>
                          <v-card-subtitle>
                            <span class="subheading">
                              <span v-if="item.is_rescuer">Rescuer</span>
                              <span v-else>User</span> </span
                            ><br />
                            <span class="subheading">{{ item.email }}</span
                            ><br />
                            <span class="subheading">{{ item.phone }}</span>
                          </v-card-subtitle>
                          <v-card-actions>
                            <span><v-icon small color="green" class="ml-5" @click="openEditDialog(item)">mdi-pencil</v-icon></span>
                            <span><v-icon small color="red" class="ml-5"  @click="openDeleteDialog(item)">mdi-delete</v-icon></span>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout> -->
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center tableheading">Name</th>
                              <th class="text-center tableheading">User/Rescuer</th>
                              <th class="text-center tableheading">Email</th>
                              <th class="text-center tableheading">Phone</th>
                              <!-- <th class="text-center tableheading">Edit</th> -->
                              <th class="text-center tableheading">Action</th>
                              <!-- <th class="text-center tableheading">Delete</th> -->
                            </tr>
                          </thead>
                          <tbody class="tablesubheading">
                            <tr v-for="(item, index) in userlist" :key="index" class="text-center">
                              <td>
                                <span v-if="item && item.name">{{
                                  item.name
                                }}</span>
                                <span v-else>-</span>
                              </td>
                              <td>
                                <span v-if="item.is_rescuer">Rescuer</span>
                                <span v-else>User</span>
                              </td>
                              <td>
                                <span v-if="item && item.email">{{
                                  item.email
                                }}</span>
                                <span v-else>-</span>
                              </td>
                              <td>
                                <span v-if="item && item.phone">{{
                                  item.phone
                                }}</span>
                                <span v-else>-</span>
                              </td>
                              <td>
                                <span
                                  ><v-icon
                                    color="green"
                                    small
                                    @click="openEditDialog(item)"
                                    >mdi-pencil</v-icon
                                  ></span
                                >
                                <span
                                  ><v-icon
                                    color="red"
                                    small
                                    class="ml-5"
                                    @click="openDeleteDialog(item)"
                                    >mdi-delete</v-icon
                                  ></span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="userlist && userlist.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              :total-visible="7"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout wrap justify-center pl-5>
          <v-flex xs12 class="text-center">
            <span class="heading">No Data Found!</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="confirmDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="itemHeading">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this user?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="confirmDeleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteUser"
            class="itemValue"
          >
            <span style="color: #fff">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      confirmDeleteDialog: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
  },
  methods: {
    openDeleteDialog(item) {
      this.deleteUserItem = item;
      this.confirmDeleteDialog = true;
    },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "DELETE",
        url: "/user/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteUserItem._id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = "User deleted successfully.";
            this.showSnackBar = true;
            this.appLoading = false;
            this.confirmDeleteDialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openEditDialog(item) {
      this.$router.push({
        path: "/edituser",
        query: {
          id: item._id,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/alluserslist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.userlist = response.data.data;
              this.Pagelength = response.data.pages;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.commonname,
          scientificName: this.scientificname,
          states: this.state,
          rname: this.reginonalname,
          distribution: this.distribution,
          characteristics: this.scalation,
          description: this.description,
          venomType: this.venomtype,
          family: this.family,
          occurence: this.occurence,
          wlpa: this.wlpa,
          iucn: this.iucn,
          shortdes: this.shortdescription,
          othernames: this.othername,
          genus: this.genus,
          experts: this.experts,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.commonname = "";
              this.scientificname = "";
              this.state = "";
              this.reginonalname = "";
              this.distribution = "";
              this.scalation = "";
              this.description = "";
              this.venomtype = "";
              this.family = "";
              this.occurence = "";
              this.wlpa = "";
              this.iucn = "";
              this.shortdescription = "";
              this.othername = "";
              this.genus = "";
              this.experts = "";
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
  <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>